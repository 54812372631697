import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {SelectControlConfig, SelectControlConfigBuilder} from '../../common/form/select-control-types';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SiUtility} from '../../common/si-utility';
import {SiAdminApiService} from '../../services/si-admin-api.service';

@Component({
  selector: 'si-company-association',
  templateUrl: './si-company-association.component.html',
  styleUrls: ['./si-company-association.component.scss']
})
export class SiCompanyAssociationComponent implements OnInit {

  public companies : any[];
  public unassignedIntegrations : any[];
  public companyIntegrations : any[];
  public companiesSelectConfig : SelectControlConfig;
  public unassociatedIntegrationsConfig : SelectControlConfig;

  public formGroup : FormGroup = this.formBuilder.group(
    {
      companyId:'',
      companyIntegrationId:''
    }
  );


  constructor(private http: HttpClient,
              private _siAdminApi : SiAdminApiService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getCompanies();
    this.getUndesignatedIntegrations();

    this.formGroup.controls['companyId'].valueChanges.subscribe((companyId : string) =>{
      this.getCompanyIntegrations(companyId);
    });
  }

  getCompanies()
  {
    this._siAdminApi.getCompanies()
      .pipe(take(1))
      .subscribe((data : any[] ) => {
        this.companies = data;
        let configBuilder = new SelectControlConfigBuilder(this.formGroup.controls['companyId']);
        configBuilder.setOptions(SiUtility.convertArrayToSelectOptions(this.companies, 'companyid','compoundname'));
        this.companiesSelectConfig = configBuilder.quickBuild('Company',true, 'No Company Selected');

      });
  }

  getUndesignatedIntegrations()
  {
    this._siAdminApi.getUnassociatedIntegrations()
      .pipe(take(1))
      .subscribe((data : any[] ) => {
        this.unassignedIntegrations = data;
        let configBuilder = new SelectControlConfigBuilder(this.formGroup.controls['companyIntegrationId']);
        configBuilder.setOptions(SiUtility.convertArrayToSelectOptions(this.unassignedIntegrations, 'companyidentifierid','compoundname'));
        this.unassociatedIntegrationsConfig = configBuilder.quickBuild('Company Identifier',true, 'No Company Identifier Selected');
      });
  }

  getCompanyIntegrations(companyId : string)
  {
    if(companyId) {
      this._siAdminApi.getCompanyIntegrations(companyId)
        .pipe(take(1))
        .subscribe((data: any[]) => {
          this.companyIntegrations = data;
        });
    }
    else {
      this.companyIntegrations = [];
    }
  }

  deassociateIntegration(companyIntegrationId)
  {
    if(companyIntegrationId)
    {
      this._siAdminApi.deassociateIntegrations({companyIntegrationId})
        .pipe(take(1))
        .subscribe((data: any) => {
          this.getUndesignatedIntegrations();
          this.getCompanyIntegrations(this.formGroup.value.companyId);

        });
    }
  }

  saveAssociation()
  {
    if(!!this.formGroup.value.companyId && !!this.formGroup.value.companyIntegrationId)
    {
      this._siAdminApi.saveCompanyAssociation(this.formGroup.value)
        .pipe(take(1))
        .subscribe((data: any) => {

          this.getUndesignatedIntegrations();
          this.getCompanyIntegrations(this.formGroup.value.companyId);
          this.formGroup.controls['companyIntegrationId'].setValue('');
        });
    }

  }

}
