import { Component, OnInit } from '@angular/core';
import {SiApiService} from '../../services/si-api.service';
import {take} from 'rxjs/operators';

type ChangeModel = {
  title: string,
  changeId: string,
  expand: boolean,
  objectives: any[]
}

@Component({
  selector: 'si-voice-dashboard',
  templateUrl: './si-voice-dashboard.component.html',
  styleUrls: ['./si-voice-dashboard.component.scss']
})
export class SiVoiceDashboardComponent implements OnInit {

  changes : ChangeModel[];

  constructor(private _siApiService : SiApiService) { }

  ngOnInit() {
    this.getChanges();
  }

  getChanges(){
    this._siApiService.makeGetCall("/voice/changes")
      .pipe(take(1))
      .subscribe((data) => {
        this.changes = data;
      })
  }

  getObjectives(change: ChangeModel){
    if(!change.expand){
      change.expand = true;
      if(!(change.objectives && change.objectives.length)) {
        this._siApiService.makeGetCall("/voice/changeObjectives",
          {params:{changeId: change.changeId}})
          .pipe(take(1))
          .subscribe((data) => {
            change.objectives = data;
          });
      }
    }
    else{
      change.expand = false;
    }
  }

}
