import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'si-voice-change-wizard',
  templateUrl: './si-voice-change-wizard.component.html',
  styleUrls: ['./si-voice-change-wizard.component.scss']
})
export class SiVoiceChangeWizardComponent implements OnInit {

  changeId : string = null;

  constructor(private _route: ActivatedRoute,) { }

  ngOnInit() {

    this._route.params.subscribe((params: {[key: string]: string}) => {
      if('changeId' in params){
        this.changeId = params.changeId;
      }
    });
  }


}
