import {AbstractControl, FormControl} from "@angular/forms";

export abstract class FormControlConfigBuilder{
  public config : any = {
    control: undefined,
    id: "",
    isRequired: false,
    label: undefined,
    name: "",
    validators: undefined
  };
  constructor(formControl : FormControl | AbstractControl)
  {
    this.config.control = <FormControl>formControl;
  }


  public setRequired(isRequired: boolean = true)
  {
    this.config.isRequired = isRequired;
  }
  public setLabel(text : string, i18nTag?: string){
    this.config.label = {
      defaultText: text,
      i18nTag: i18nTag
    }
  }

  public setId(text : string)
  {
    this.config.id = text;
  }

  public quickCreate(labelText: string , isRequired:boolean = false)
  {
    this.setLabel(labelText)
    this.setRequired(isRequired);
    this.setId(labelText.replace(/[^0-9A-Za-z]/g, ''));
  }
}

export class FormControlConfig {


  public control: FormControl = null;
  public id: string = null;
  public label: Ii18nText = null;
  public name: string = null;
  public validators: any = null;
  public isRequired : boolean = null;



}

export interface IFormControlConfig{
  formControl?:FormControl ;
  label: Ii18nText;
  name: string;
  id: string;
  validators : any;
}

export interface Ii18nText{
  defaultText: string;
  i18nTag?: string;
}
