import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {SelectControlConfig, SelectControlConfigBuilder} from '../../common/form/select-control-types';
import {SiUtility} from '../../common/si-utility';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SiUtilitiesService} from '../../services/si-utilities.service';
import {SiCompanyAdminApiService} from '../../services/si-company-admin-api.service';

@Component({
  selector: 'si-manage-user-information',
  templateUrl: './si-manage-user-information.component.html',
  styleUrls: ['./si-manage-user-information.component.scss']
})
export class SiManageUserInformationComponent implements OnInit {

  public companyIntegrationsConfig : SelectControlConfig;
  public formGroup : FormGroup = this.formBuilder.group({
      'companyIntegrationId':''
    });

  public uploadGroup : FormGroup = this.formBuilder.group({
    userFile:''
  });

  private userFile : File = null;

  constructor(private _siCompanyAdminApi : SiCompanyAdminApiService,
              private formBuilder : FormBuilder) { }

  ngOnInit() {
    this.getCompanyIntegrations()
  }

  downloadUsers(){
    this._siCompanyAdminApi.getUsers(this.formGroup.value.companyIntegrationId)
      .pipe(take(1))
      .subscribe((response) => {
        const fileName : string = response.headers.get('file-name');
        SiUtilitiesService.downloadCsv(response.body, fileName);
      }, (error) => {
        console.error('getUsers',error);
      });
  }

  uploadUsers(){
    console.log(this.uploadGroup.value);
    if(this.userFile)
    {
      let formData = SiUtilitiesService.getFileFormData(this.userFile, 'userFile');

      this._siCompanyAdminApi.uploadUsers(formData)
        .subscribe((data) => {

        });
    }
  }

  addFile(files)
  {
    if(files) {
      this.userFile = files.item(0);
    }
  }

  private getCompanyIntegrations(){
    this._siCompanyAdminApi.listCompanyIntegrations()
      .pipe(take(1))
      .subscribe((integrations: any[]) => {
        let configBuilder = new SelectControlConfigBuilder(this.formGroup.controls['companyIntegrationId']);
        configBuilder.setOptions(SiUtility.convertArrayToSelectOptions(integrations, 'companyidentifierid','compoundname'));
        this.companyIntegrationsConfig = configBuilder.quickBuild('Company Integrations',true, 'No Company Integration Selected');

      })
  }

}
