import {FormControlConfig, FormControlConfigBuilder, Ii18nText} from './form-control-types';
import {AbstractControl, FormControl} from '@angular/forms';

export class TextFieldConfigBuilder extends FormControlConfigBuilder{
  config : TextFieldConfig;

  constructor(formControl : FormControl | AbstractControl)
  {
    super(formControl);
    const textFieldConfig :TextFieldConfig = {
      append: undefined,
      control: undefined,
      id: "",
      isRequired: false,
      label: undefined,
      name: "",
      placeholder: undefined,
      prepend: undefined,
      validators: undefined
    };
    this.config = {...textFieldConfig, ...this.config};
  }

  public build()
  {
    return new TextFieldConfig(this.config);
  }

  public quickBuild(labelText: string , isRequired:boolean = false)
  {
    super.quickCreate(labelText, isRequired);
    return this.build();
  }


}

export class TextFieldConfig extends FormControlConfig{

  public placeholder:Ii18nText = null;
  public append:Ii18nText = null;
  public prepend:Ii18nText = null;

  constructor(config: ITextFieldConfig){
    super();

    for(const key in this){
      if(<any>config && (<any>config)[key] !== undefined ){
        this[key] = (<any>config)[key];
      }
    }

  }
}

export interface ITextFieldConfig {
  placeholder: Ii18nText;
  append: Ii18nText;
  prepend: Ii18nText;
}
