import {Component, Input, OnInit} from '@angular/core';
import {INavItem} from "../types/si-nav-types";
import {SiResizeService, WindowWidthBand} from '../../services/si-resize.service';

@Component({
  selector: 'si-nav-item',
  templateUrl: './si-nav-item.component.html',
  styleUrls: ['./si-nav-item.component.scss']
})
export class SiNavItemComponent implements OnInit {

  @Input() config : INavItem;

  WindowWidthBand = WindowWidthBand;
  currentWidthBand : WindowWidthBand;

  showSubRoutes: boolean;
  cancelExit: boolean = true;
  constructor(private _siResizeService : SiResizeService) { }

  ngOnInit() {
    this._siResizeService.getWindowBandSubscription()
      .subscribe((widthBand: WindowWidthBand) => {
        this.currentWidthBand = widthBand;
      });
  }

  mouseEnter()
  {
    this.cancelExit = true;
    // this.showSubRoutes = true;
  }

  click(event)
  {
    if(this.config.subRoutes) {
      event.stopPropagation();
      // this.showSubRoutes = true;
    }
  }

  // submenuClick()
  // {
  //   this.showSubRoutes = false;
  // }

  mouseLeave()
  {
    this.cancelExit = false;
    setTimeout(() => {
      if(!this.cancelExit) {
        this.showSubRoutes = false;
      }
    }, 600)
  }

}
