import {FormControlConfig, FormControlConfigBuilder, Ii18nText} from './form-control-types';
import {ITextFieldConfig, TextFieldConfig} from './text-field-control-types';
import {AbstractControl, FormControl} from '@angular/forms';

export class SelectControlConfigBuilder extends FormControlConfigBuilder {
  config :SelectControlConfig;

  constructor(control: FormControl | AbstractControl) {
    super(control);
    const selectConfig : SelectControlConfig ={
      control: undefined,
      id: "",
      isRequired: false,
      label: undefined,
      name: "",
      noSelectionText: undefined,
      options: [],
      validators: undefined
    };
    this.config = {...selectConfig, ...this.config};
  }

  public build()
  {
    return new SelectControlConfig(this.config);
  }

  public setOptions(options:ISelectOptions[])
  {
    this.config.options = options;
  }

  public quickBuild(labelText: string , isRequired:boolean = false, unselectedText : string)
  {
    super.quickCreate(labelText, isRequired);
    if(unselectedText) {
      this.config.noSelectionText = {
        value: '',
        text: unselectedText
      };
    }
    return this.build();
  }

}

export class SelectControlConfig extends FormControlConfig{

  public noSelectionText:ISelectOptions = {
    value:'',
    text:'Nothing selected'
  };
  public options : ISelectOptions[] = [];

  constructor(config: ISelectConfig){
    super();

    for(const key in this){
      if(<any>config && (<any>config)[key] !== undefined ){
        this[key] = (<any>config)[key];
      }
    }

  }
}

export interface ISelectConfig {
  noSelectionText:ISelectOptions,
  options: ISelectOptions[]
}


export type ISelectOptions =
{
  value:string;
  text: string;
}
