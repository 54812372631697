import { Injectable } from '@angular/core';
import {SiApiService} from './si-api.service';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SiVoiceApiService {

  baseMethods : any = {
    change: 'change',
    changeWhy: 'changeWhy',
    changeObjective:'changeObjective',
    changePerson:'changePerson',
    changeQuestionnaire: 'changeQuestionnaire',
    changeResponse:'changeResponse',
    changePersonQuestion:'changePersonQuestion',
    changeQuestion:'changeQuestion'
  };

  constructor(private _siApi: SiApiService) { }

  getAll(baseMethod: string, params:{[key:string]:string}) : Observable<any>{
    return this._siApi.makeGetCall(`/voice/${baseMethod}s`, {params})
      .pipe(take(1));
  }

  getById(baseMethod: string, id: string) : Observable<any>{
    return this._siApi.makeGetCall(`/voice/${baseMethod}/${id}`)
      .pipe(take(1));
  }

  createRecord(baseMethod: string, record:{[key: string]:string}) : Observable<any>{
    return this._siApi.makePostCall(`/voice/${baseMethod}`, record)
      .pipe(take(1));
  }

  updateRecord(baseMethod: string, record:{[key:string]:string}, id : string) : Observable<any> {
    return this._siApi.makePutCall(`/voice/${baseMethod}/${id}`, record)
      .pipe(take(1));
  }

  deleteRecord(baseMethod: string, id:string):Observable<any>{
    return this._siApi.makeDeleteCall(`/voice/${baseMethod}/${id}`)
      .pipe(take(1));
  }
}
