import { Injectable } from '@angular/core';
import {SiApiService} from './si-api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiMetricsApiService {

  constructor(private _siApiService : SiApiService) { }

  public getResponseTimes(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getResponseTimes", postBody)
  }

  public getMessageData(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getMessageData", postBody)
  }

  public getMessageSenderLineData(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getMessageSenderLineData", postBody)
  }

  public getCompanyTopThrees(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getCompanyTopThrees", postBody)
  }

  public getAfterHoursBarData(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getAfterHoursBarData", postBody)
  }

  public getRecipientData(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getRecipientData", postBody)
  }

  public getSenderData(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getSenderData", postBody)
  }

  public getSenderHoursData(postBody:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/metrics/getSenderHoursData", postBody)
  }
}
