import { Injectable } from '@angular/core';
import {SiApiService} from './si-api.service';

@Injectable({
  providedIn: 'root'
})
export class SiAuthApiService {

  constructor(private _siApiService: SiApiService) { }

  public authenticate(creds:any)
  {
    return this._siApiService.makePostCall("/auth/authenticate", creds);
  }

  public registerUser(newUserInfo:any)
  {
    return this._siApiService.makePostCall("/auth/registerUser", newUserInfo);
  }
}
