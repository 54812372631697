import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {SiMetricsApiService} from '../../services/si-metrics-api.service';

@Component({
  selector: 'si-dashboard',
  templateUrl: './si-dashboard.component.html',
  styleUrls: ['./si-dashboard.component.scss']
})
export class SiDashboardComponent implements OnInit {

  lineChartData: any = [];
  chartHeaders: any = [];
  stackedBarChartData : any = null;
  groupedBarChartData : any = null;
  topThrees : any[];

  constructor(private _siMetricsApi : SiMetricsApiService) {
  }

  nodes: any[];
  links: any[];

  ngOnInit() {
  }

  getFilterVals(filterVals: { startDate: string, endDate: string, groupBy: string, colorKey: string }) {
    const postBody = {
      startDate: filterVals.startDate,
      endDate: filterVals.endDate,
      groupingKey: filterVals.groupBy,
      groupName: null,
      colorKey: filterVals.groupBy == 'person' ? filterVals.colorKey : null
    };
    if (!['person', 'location'].includes(filterVals.groupBy)) {
      postBody.groupingKey = 'group';
      postBody.groupName = filterVals.groupBy;
    }
    this.getMessageDataForNetwork(postBody);
    this.getMessageDataForLineChart({seriesKey:postBody.groupingKey,
      groupName: postBody.groupName, startDate: postBody.startDate, endDate: postBody.endDate})
    this.getMessagesForStackedBarChart(postBody);
    this.getResponseTimes(postBody);
    this.getTopThrees(postBody);
  }

  getResponseTimes(postBody:{
    startDate: string,
    endDate: string,
    groupingKey: string,
    groupName?: string,
    colorKey:string
  }) {
    this._siMetricsApi.getResponseTimes(postBody)
      .pipe(take(1))
      .subscribe((data : any) => {
        this.groupedBarChartData = data;
      });
  }

  getMessageDataForNetwork(postBody: {
    startDate: string,
    endDate: string,
    groupingKey: string,
    groupName?: string,
    colorKey:string
  }) {
    this._siMetricsApi.getMessageData(postBody)
      .pipe(take(1))
      .subscribe((data: { nodes: any[], links: any[] }) => {
        if(data) {
          this.nodes = data.nodes;
          this.links = data.links;
        }
      });

  }

  getMessageDataForLineChart(postBody: {seriesKey:string, groupName:string, startDate:string, endDate:string})
  {
    this._siMetricsApi.getMessageSenderLineData(postBody)
      .pipe(take(1))
      .subscribe((data) => {
        this.lineChartData = data;
      });
  }

  getTopThrees(postBody: {
    startDate: string,
    endDate: string,
    groupingKey: string,
    groupName?: string,
    colorKey:string
  }) {
    this._siMetricsApi.getCompanyTopThrees(postBody)
      .pipe(take(1))
      .subscribe((data : any[]) => {
        this.topThrees = data;
      });
  }

  getMessagesForStackedBarChart(postBody: {
    startDate: string,
    endDate: string,
    groupingKey: string,
    groupName?: string,
    colorKey:string
  })
  {
    this._siMetricsApi.getAfterHoursBarData(postBody)
      .pipe(take(1))
      .subscribe((barData: any) =>{
        this.stackedBarChartData = barData;
      });
  }


}
