import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SiUtilitiesService} from '../../services/si-utilities.service';
import {SiCompanyAdminApiService} from '../../services/si-company-admin-api.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'si-manage-user-groups',
  templateUrl: './si-manage-user-groups.component.html',
  styleUrls: ['./si-manage-user-groups.component.scss']
})
export class SiManageUserGroupsComponent implements OnInit {

  public uploadFormGroup : FormGroup = this.formBuilder.group({
    userGroupFile:''
  });

  private userGroupFile : File = null;

  constructor(private formBuilder: FormBuilder,
              private _siCompanyAdminApi : SiCompanyAdminApiService) { }



  ngOnInit() {
  }

  addFile(files)
  {
    if(files) {
      this.userGroupFile = files.item(0);
    }
  }

  downloadUserGroups()
  {
    this._siCompanyAdminApi.downloadUserGroups()
      .pipe(take(1))
      .subscribe((response) => {
        const fileName : string = response.headers.get('file-name');
        SiUtilitiesService.downloadCsv(response.body, fileName)
      }, (error) => {
        console.error('getUserGroupsFile', error);
      });
  }

  uploadUserGroups()
  {
    if(this.userGroupFile)
    {
      const formData = SiUtilitiesService.getFileFormData(this.userGroupFile, 'userGroupFile');
      this._siCompanyAdminApi.uploadUserGroups(formData).subscribe(()=>{});
    }

  }

}
