import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SiUtility} from '../../../common/si-utility';
import {Chart} from 'chart.js';
import * as d3 from 'd3';

@Component({
  selector: 'si-grouped-barchart',
  templateUrl: './si-grouped-barchart.component.html',
  styleUrls: ['./si-grouped-barchart.component.scss']
})
export class SiGroupedBarchartComponent implements OnInit, OnChanges {
  @Input() chartId : string = "test-grouped-barchart-id";
  @Input() chartTitle : string;
  @Input() chartData : {id:any, text:string, [key:string]:any}[];

  private _groupedBarChart : any;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    this.drawChart();
  }

  drawChart(){
    if(this.chartData){
      const canvas = <HTMLCanvasElement>document.getElementById(this.chartId);
      if(this._groupedBarChart){
        this._groupedBarChart.destroy();
      }
      const formattedData = this.processData();
      console.log(formattedData);
      this._groupedBarChart = new Chart(canvas, {
        type:'bar',
        data:formattedData,
        options:{
          responsive: true,
            legend: {
              position: "top"
            },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      })
    }
  }

  processData(){
    const labels = [];
    const datasetsLabels = [];
    const datasets = [];
    const data = {};
    for(let obj of this.chartData)
    {
      for(let key in obj){
        if(key != 'text' && key != 'id'){
          let decodedKey = SiUtility.snakeCaseToWords(key);
          console.log('decodedKey', decodedKey);
          if(!datasetsLabels.includes(decodedKey))
            datasetsLabels.push(decodedKey);
          if(data[decodedKey]){
            data[decodedKey].push(obj[key]);
          }
          else{
            data[decodedKey] = [obj[key]];
          }
        }
      }
      labels.push(obj.text);
    }
    console.log(data, labels);
    const colorScheme = d3.scaleOrdinal().domain(datasetsLabels.sort()).range(d3.schemePaired);
    for(let label of datasetsLabels){
      console.log(label);
      datasets.push({
        label:label,
        backgroundColor:colorScheme(label),
        borderColor:colorScheme(label),
        borderWidth:1,
        data: data[label]
      });
    }

    return {
      datasets:datasets,
      labels:labels
    }
  }

}
