/// <reference types="google.visualization"/>
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Chart} from 'chart.js';
import * as d3 from 'd3';
import * as moment from 'moment';

@Component({
  selector: 'si-linechart',
  templateUrl: './si-linechart.component.html',
  styleUrls: ['./si-linechart.component.scss']
})
export class SiLinechartComponent implements OnInit, OnChanges {

  @Input() chartId : string = "test-id";
  @Input() chartTitle : string;
  @Input() chartData : {seriesid:string,
      seriesname:string,
      x:any,
      y:number}[];
  @Input() chartHeaders : any[];

  options : any;
  private _lineChart :any;

  constructor() { }

  ngOnInit() {
    this.options = {
      title: this.chartTitle,
      legend:{
        position:'bottom'
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.drawChart();
  }

  drawChart(){
    if(this.chartData) {
      const canvas = <HTMLCanvasElement>document.getElementById(this.chartId);
      if(canvas) {
        if(this._lineChart){
          this._lineChart.destroy();
        }
        const datasets = [];
        const xLabels = [];
        const series :{[key:string]:{x:any, y:number}[]} = {};

        this.chartData.forEach((seriesObj, index) => {
          const dateX = moment(seriesObj.x).format("MMM DD, YYYY");
          if(xLabels.length === 0 || xLabels[xLabels.length-1] !== dateX)
          {
            xLabels.push(dateX);
          }
          if(!series[seriesObj.seriesname])
          {
            series[seriesObj.seriesname] = []
          }
          series[seriesObj.seriesname].push({x:dateX, y:seriesObj.y});
        });

        const seriesLabels = Object.keys(series).sort();
        const colorScheme =  d3.scaleOrdinal().domain(seriesLabels).range(d3.schemePaired);
        seriesLabels.forEach((seriesKey:string) => {
          datasets.push({
            label:seriesKey,
            data: series[seriesKey],
            spanGaps:true,
            fill:false,
            borderColor: colorScheme(seriesKey),
            backgroundColor: colorScheme(seriesKey),
            lineTension:0.3
          });
        });


        this._lineChart = new Chart(canvas, {
          type: 'line',
          data:{
            labels: xLabels,
            datasets: datasets,
          },options: {
            bezierCurve:false,
            scales: {
              xAxes: [{
                type: 'time'
              }]
            }
          }
        });
      }
    }
  }


}
