import {Component, Input, OnInit} from '@angular/core';
import {SelectControlConfig} from '../select-control-types';

@Component({
  selector: 'si-select',
  templateUrl: './si-select.component.html',
  styleUrls: ['./si-select.component.scss']
})
export class SiSelectComponent implements OnInit {

  @Input() config: SelectControlConfig;

  constructor() { }

  ngOnInit() {
  }

}
