import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'si-headline-metric',
  templateUrl: './si-headline-metric.component.html',
  styleUrls: ['./si-headline-metric.component.scss']
})
export class SiHeadlineMetricComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
