import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AuthService} from '../../common/authentication/auth.service';
import {SiUtility} from '../../common/si-utility';
import {SelectControlConfig, SelectControlConfigBuilder} from '../../common/form/select-control-types';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SiAdminApiService} from '../../services/si-admin-api.service';

@Component({
  selector: 'si-register-user',
  templateUrl: './si-register-user.component.html',
  styleUrls: ['./si-register-user.component.scss']
})
export class SiRegisterUserComponent implements OnInit {

  public formGroup : FormGroup;
  public errorMessage : string;
  public companySelectConfig : SelectControlConfig;

  constructor(private formBuilder : FormBuilder,
              private authService : AuthService,
              public utils : SiUtility,
               private _siAdminApi: SiAdminApiService) { }

  ngOnInit()
  {
    this.formGroup = this.formBuilder.group({
      companyid:'',
      username:['', Validators.required],
      password:['', Validators.required],
      confirmPassword:['', Validators.required]
    }, {validators: [this.comparePasswordAndConfirmation]});
    this.getCompanies()
  }

  register()
  {
    if(!this.formGroup.valid)
    {
      this.utils.markFormGroupControlsAsTouched(this.formGroup);
      return;
    }
    console.log(this.formGroup.value);
    this.authService.registerUser(this.formGroup.value)
      .subscribe((user) => {
        console.debug('new user', user);
      });
  }

  private getCompanies()
  {
    this._siAdminApi.getCompanies()
      .pipe(take(1))
      .subscribe((data : any[] ) => {
        const companies = data;
        let configBuilder = new SelectControlConfigBuilder(this.formGroup.controls['companyid']);
        configBuilder.setOptions(SiUtility.convertArrayToSelectOptions(companies, 'companyid','compoundname'));
        this.companySelectConfig = configBuilder.quickBuild('Company',true, 'SYSTEM ADMIN');

      });
  }


  private comparePasswordAndConfirmation : ValidatorFn = (formGroup: FormGroup) =>
  {
    const password = formGroup.get('password');
    const confirmedPassword = formGroup.get('confirmPassword');

    return password.value === confirmedPassword.value ? null : {'mismatch':true};
  }
}
