import { Component, OnInit } from '@angular/core';
import {take} from 'rxjs/operators';
import {SiMetricsApiService} from '../../services/si-metrics-api.service';

@Component({
  selector: 'si-employee-profile',
  templateUrl: './si-employee-profile.component.html',
  styleUrls: ['./si-employee-profile.component.scss']
})
export class SiEmployeeProfileComponent implements OnInit {

  public errorMessage : string = '';
  senderData : {data:number[],labels:string[]};
  recipientData : {data:number[],labels:string[]};
  senderHoursData : any;

  private _filters : any = {
    senderId:null,
    recipientId:null,
    startDate:null,
    endDate:null,
    groupingKey:null,
    groupName:null
  };

  constructor(private _siMetricsApi: SiMetricsApiService) { }

  ngOnInit() {
  }


  getFilters(event: {startDate:string, endDate:string, groupBy:string, employeeId:string}){
    this._filters.startDate = event.startDate;
    this._filters.endDate = event.endDate;
    this._filters.senderId = event.employeeId;
    this._filters.recipientId = event.employeeId;

    if(['person','location'].includes(event.groupBy))
    {
      this._filters.groupingKey = event.groupBy;
      this._filters.groupName = null;
    }
    else {
      this._filters.groupingKey = 'group';
      this._filters.groupName = event.groupBy;
    }
    this.getDashboardData()
  }

  getDashboardData()
  {
    if(!this._filters.recipientId){
      this.errorMessage = "Please choose a person";
      return;
    }
    this._siMetricsApi.getRecipientData(this._filters)
      .pipe(take(1))
      .subscribe((data) => {
        this.recipientData = data[0];
      });
    this._siMetricsApi.getSenderData(this._filters)
      .pipe(take(1))
      .subscribe((data) => {
        this.senderData = data[0];
      });
    this._siMetricsApi.getSenderHoursData(this._filters)
      .pipe(take(1))
      .subscribe((data) => {
        this.senderHoursData = data;
      });
  }
}
