import {HostListener, Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {EventManager} from '@angular/platform-browser';

export enum WindowWidthBand  {
  SMALL = "s",
  MEDIUM = "m",
  LARGE = "l"
}

@Injectable({
  providedIn: 'root'
})
export class SiResizeService {

  private _windowResizeBehaviorSubject : BehaviorSubject<number> = new BehaviorSubject<number>(window.innerWidth);
  private _windowWidthBandBehaviorSubject : BehaviorSubject<WindowWidthBand> = new BehaviorSubject<WindowWidthBand>(this._calcSize(window.innerWidth));

  private _currentWindowBand : WindowWidthBand = this._calcSize(window.innerWidth);

  constructor(private _eventManager :EventManager)
  {
    this._eventManager.addGlobalEventListener(
      'window','resize', this.onResize.bind(this))
  }

  private _calcSize(width: number) : WindowWidthBand
  {
    if(width < 600)
    {
      return WindowWidthBand.SMALL
    }
    else if (width < 990 && width >= 600)
    {
      return WindowWidthBand.MEDIUM
    }
    else {
      return WindowWidthBand.LARGE
    }
  }

  public getWindowResizeSubscription() : Observable<number>
  {
    return this._windowResizeBehaviorSubject.asObservable()
  }

  public getWindowBandSubscription() : BehaviorSubject<WindowWidthBand>
  {
    return this._windowWidthBandBehaviorSubject;
  }

  onResize(event : UIEvent)
  {
    const width = (<Window>event.target).innerWidth;
    console.log('window width', width);
    this._windowResizeBehaviorSubject.next(width);
    const calculatedBand = this._calcSize(width);
    if(calculatedBand != this._currentWindowBand)
    {
      this._currentWindowBand = calculatedBand;
      this._windowWidthBandBehaviorSubject.next(calculatedBand);

    }
  }
}
