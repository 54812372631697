import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {take} from 'rxjs/operators';
import {SiUtility} from '../si-utility';
import {SelectControlConfig, SelectControlConfigBuilder} from '../form/select-control-types';
import {FormBuilder} from '@angular/forms';
import {SiCompanyAdminApiService} from '../../services/si-company-admin-api.service';

@Component({
  selector: 'si-user-select',
  templateUrl: './si-user-select.component.html',
  styleUrls: ['./si-user-select.component.scss']
})
export class SiUserSelectComponent implements OnInit {

  @Output() changePerson : EventEmitter<any> = new EventEmitter<any>();

  public config : SelectControlConfig;
  formGroup  = this._formBuilder.group({
    employeeid:''
  });

  constructor(private _siCompanyAdminService : SiCompanyAdminApiService, private _formBuilder : FormBuilder) { }

  ngOnInit() {
    this._siCompanyAdminService.getCompanyEmployees()
      .pipe(take(1))
      .subscribe((employees: any) => {
        const convertedEmps = SiUtility.convertArrayToSelectOptions(employees, 'id','fullName');
        const builder = new SelectControlConfigBuilder(this.formGroup.controls['employeeid']);
        builder.setOptions(convertedEmps);
        this.config = builder.quickBuild('Employees',false,'Choose an Employee');

      });

    this.formGroup.valueChanges.subscribe((formValue) =>{
      this.changePerson.emit(formValue)
    })
  }

}
