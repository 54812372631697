import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SiUtilitiesService {

  constructor() { }

  public static downloadCsv(data : any, fileName : string)
  {
    const blob = new Blob([data], {type:'text/csv'});
    let url = window.URL.createObjectURL(blob);
    let anchor = document.createElement('a');
    if(fileName) {
      anchor.download = fileName;
    }
    else
    {
      anchor.download = 'file.csv';
    }
    anchor.href = url;
    anchor.click();
    // window.open(url);
  }

  public static getFileFormData(file: File, key?: string) : FormData
  {
    let formData = new FormData();
    formData.append(key || 'file', file, file.name);
    return formData;
  }

  public static getLineChartData(collection: any[], timeField : string, seriesField : string, measureField : string){
    let headerRow = [timeField];
    let rows = {};
    for(let i = 0; i < collection.length; i++){
      let row = collection[i];
      let key = row[timeField];
      if(!rows[key])
      {
        rows[key] = {};
      }
      rows[key][row[seriesField]] = row[measureField];
      if(!headerRow.includes(row[seriesField])){
        headerRow.push(row[seriesField])
      }
    }
    let results = [headerRow];
    Object.keys(rows).sort().forEach(rowKey => {
      let newRow = new Array(headerRow.length);
      newRow[0] = +rowKey.substring(0,3);
      for(let column of headerRow)
      {
        if(column !== timeField) {
          newRow[headerRow.indexOf(column)] = +rows[rowKey][column] || 0 ;
        }
      }
      results.push(newRow);
    });
    return results;
  }

}
