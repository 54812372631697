import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';


@Component({
  selector: 'si-network-diagram',
  templateUrl: './si-network-diagram.component.html',
  styleUrls: ['./si-network-diagram.component.scss']
})
export class SiNetworkDiagramComponent implements OnInit, OnChanges {
  @ViewChild('graphSVG', { static: true }) graphSVG: ElementRef;



  @Input() diagramId: string;
  @Input() nodes: SiNode[];
  @Input() links: SiLink[];

  public networkHeight : number = 600;

  constructor() {
  }

  ngOnInit() {
    this.drawGraph();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.drawGraph();
  }

  drawGraph() {
    const svg = d3.select(this.graphSVG.nativeElement);
    svg.selectAll('*').remove();
    let scaleColor = this.getColorScheme();

    this.links.forEach(link => {
      link.source = this.nodes.find(n => n.nodeid == link.sourceid);
      link.target = this.nodes.find(n => n.nodeid == link.targetid);
    });

    svg.append("defs").selectAll("marker")
      .data(["suit", "none","undefined","licensing", "resolved"])
      .enter().append("marker")
      .attr("id", function(d) { return d; })
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 15)
      .attr("refY", -1.5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5");

    let sim = d3.forceSimulation(this.nodes)
      .force('link', d3.forceLink().distance(140).links(this.links))
      .force('charge', d3.forceManyBody())
      .force('collision', d3.forceCollide().radius(function(d: any) {
        return 10;
      }))//todo: add normalized weight
      .force('x', d3.forceX(this.graphSVG.nativeElement.scrollWidth / 2))
      .force('y', d3.forceY(this.networkHeight / 2));

    let path = svg.append('g').selectAll('path')
      .data(this.links)
      .enter().append('path')
      .attr('class', function(d) {
        return 'link';
      })
      .attr('style', function(d) {
        return `stroke-width:${d.linkweight}px;
                fill: none;
                stroke: #666;`;
      })
      .attr('marker-end', function(d) {
        return 'url(#' + d.type || 'none' + ')';
      });

    let text = svg.append('g').selectAll('text')
      .data(sim.nodes())
      .enter().append('text')
      .attr('x', function(d) {
        return 13 + (2*d.nodeweight);
      })
      .attr('y', '.35em')
      .text(function(d) {
        return d.nodetext;
      });

    function dragstarted(d) {
      if (!d3.event.active) {
        sim.alphaTarget(0.3).restart();
      }
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) {
        sim.alphaTarget(0);
      }
      d.fx = null;
      d.fy = null;
    }

    let circle =
      svg.append('g').selectAll('circle')
        .data(sim.nodes())
        .enter()
        .append('circle')
        .attr('r', function(d) {
          return 10 + (2*d.nodeweight);
        })
        .style('fill', function(d) {
          return scaleColor(d.nodecolor);
        })
        .call(d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended));

    let tick = () => {
      path.attr('d', this.linkArc);
      circle.attr('transform', this.transform);
      text.attr('transform', this.transform);

    };

    sim.on('tick', tick);
  }

  getColorScheme() {
    let colorObject = {};
    this.nodes.forEach(node => {
      colorObject[node.nodecolor] = true;
    });
    return d3.scaleOrdinal().domain(Object.keys(colorObject).sort()).range(d3.schemePaired);
  }

  linkArc(d) {
    var dx = d.target.x - d.source.x,
      dy = d.target.y - d.source.y,
      dr = Math.sqrt(dx * dx + dy * dy),
      plusdr = dr;
    return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + plusdr + ' 0 0,1 ' + d.target.x + ',' + d.target.y;
  }

  transform(d) {
    return 'translate(' + d.x + ',' + d.y + ')';
  }


}


type SiNode  = {
  x:number;
  y:number;
  nodeid: string;
  nodetext: string;
  nodecolor: string;
  nodeweight: number;
  nodeinformation?: {[key:string]:string};
}
type SiLink = {
  sourceid: string;
  source: SiNode;
  targetid: string;
  target: SiNode
  linkweight: number;
  linkinformation?: {[key:string]:string};
}
