import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class SiGraphqlService {

  private _graphQlUrl: string = '/graphql';

  constructor(private _http: HttpClient) { }


  public query(query : String) : Observable<any>{
    return this._http.post(BASE_URL + this._graphQlUrl, {operationName:null, query:query, variables:{}});
  }

  public getQueryFromPaths(query:string, args: any, paths: string[])
  {
    console.log('paths',paths);
    let queries :string[] = [];
    paths.forEach(path => {
      let keys = path.split('.');
      let queryString = "";
      for(let i = keys.length - 1; i >=0; i--){
        if(i > 0)
        {
          queryString = `{${keys[i]}${queryString}}`;
        }
        else {
          queryString = keys[i] + queryString;
        }
      }
      queries.push(queryString)
    });

    return `{${query}{${queries.join(',')}}}`;
  }

}
