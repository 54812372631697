import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Chart} from 'chart.js';
import * as d3 from 'd3';


@Component({
  selector: 'si-pie-chart',
  templateUrl: './si-pie-chart.component.html',
  styleUrls: ['./si-pie-chart.component.scss']
})
export class SiPieChartComponent implements OnInit, OnChanges {

  @Input() chartId: string = 'piechart';
  @Input() chartTitle: string;
  @Input() chartType : 'pie' | 'doughnut' = 'doughnut';
  @Input() chartData : {labels:string[], data:number[]};

  private _pieChart : any;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if(this.chartData)
    {
      const canvas = <HTMLCanvasElement>document.getElementById(this.chartId)
      if(canvas)
      {
        if(this._pieChart){
          this._pieChart.destroy();
        }

        const colorScheme =  d3.scaleOrdinal().domain(this.chartData.labels.slice(0).sort()).range(d3.schemePaired);
        const ctx = canvas.getContext('2d');
        const bgColor = this.chartData.labels.map(colorScheme);
        this._pieChart = new Chart(ctx,{
          type: this.chartType,
          data:{
            datasets:[{
              data: this.chartData.data,
              backgroundColor: bgColor}],
            labels: this.chartData.labels
          }
        });
      }
    }
  }

}
