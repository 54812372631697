import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {SiUtility} from '../../common/si-utility';
import {TextFieldConfig, TextFieldConfigBuilder} from '../../common/form/text-field-control-types';
import {HttpClient} from '@angular/common/http';
import {SiCompanyApiService} from '../../services/si-company-api.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'si-create-company',
  templateUrl: './si-create-company.component.html',
  styleUrls: ['./si-create-company.component.scss']
})
export class SiCreateCompanyComponent implements OnInit {

  public formGroup = this.formBuilder.group({
    companyid:[''],
    companycode: ['', Validators.required],
    companyname: ['', Validators.required]
  });

  public companyCodeConfig : TextFieldConfig;
  public companyNameConfig : TextFieldConfig;
  constructor(private formBuilder : FormBuilder,
              private utils : SiUtility,
              private _siCompanyApiService : SiCompanyApiService) {

  }

  ngOnInit() {
    this.companyCodeConfig = new TextFieldConfigBuilder(this.formGroup.controls['companycode']).quickBuild('Company Code', true);
    this.companyNameConfig =new TextFieldConfigBuilder(this.formGroup.controls['companyname']).quickBuild('Company Name', true);
  }

  saveCompany()
  {
    if(!this.formGroup.valid)
    {
      this.utils.markFormGroupControlsAsTouched(this.formGroup);
      return;
    }

    this._siCompanyApiService.saveCompany(this.formGroup.value)
      .pipe(take(1))
      .subscribe((response) => {
        console.log(response)
      });
  }

}
