import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {ISelectOptions} from './form/select-control-types';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SiUtility {

  constructor()
  {

  }



  public hasFormControlErrors(formControl : FormControl | AbstractControl) : boolean
  {
    return !!formControl.touched && !!formControl.errors;
  }

  public markFormGroupControlsAsTouched(formGroup : FormGroup)
  {
    for(let key in formGroup.controls)
    {
      formGroup.controls[key].markAsTouched();
    }
  }

  public static snakeCaseToWords(snakeString: string)
  {
    return _.startCase(snakeString.replace("_", " "))
  }

  public static convertArrayToSelectOptions(array: any[], valueField: string, textField : string) : ISelectOptions[]
  {
    const results : ISelectOptions[] = [];
    array.forEach((entry : any) => {
      results.push({
        value: entry[valueField],
        text: entry[textField]
      });
    });
    return results;
  }
}
