import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {shareReplay, tap} from "rxjs/operators";
import * as moment from 'moment';
import {Observable} from "rxjs";
import {JwtHelperService} from '@auth0/angular-jwt';
import {SiAuthApiService} from '../../services/si-auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private jwtService : JwtHelperService;

  constructor(private _siAuthApiService : SiAuthApiService,
              private route: Router)
  {
    this.jwtService = new JwtHelperService();
  }

  public login(credentials:ICredentials) : Observable<any>{
    return this._siAuthApiService.authenticate(credentials)
      .pipe(tap(response => this.setSession(response)), shareReplay());
  }

  public logout(){
    localStorage.removeItem("SI_TKN");
    localStorage.removeItem("expires_at");
    this.route.navigate(['/login']);
  }

  public isLoggedIn() : boolean {
    return moment().isBefore(this.getExpiration());
  }

  public getRoles() : string[] {
    const tkn = localStorage.getItem('SI_TKN');
    if(tkn)
    {
      const decoded = this.jwtService.decodeToken(tkn);
      return decoded.roles;
    }
    return [];
  }

  public hasRole(role : string) : boolean
  {
    return this.getRoles().includes(role);
  }

  public registerUser(userCreateInfo : IUserCreateInformation){
    return this._siAuthApiService.registerUser(userCreateInfo)
      .pipe(tap(response => this.setSession(response)), shareReplay());
  }

  private getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    let expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  private setSession(response) : boolean{
    const expiresAt = moment(0).add(response.expiresAt,'second');
    localStorage.setItem('SI_TKN', response.tkn);
    localStorage.setItem('expires_at',JSON.stringify(expiresAt.valueOf()));
    return (response && response.tkn);
  }
}

export interface ICredentials {
  username : string;
  password : string;
}


export interface IUserCreateInformation {
  username : string,
  password : string,
  confirmPassword : string
}
