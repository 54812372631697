import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SiVoiceApiService} from '../../../../services/si-voice-api.service';
import {ChangeModel, ChangeObjectiveModel, ChangeQuestionModel,
  ChangeQuestionnaireModel,
  ChangeQuestionPerson, ChangeWhyModel, ChangeResponseModel} from '../../../../services/voice-types';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {take, filter} from 'rxjs/operators';


@Component({
  selector: 'si-voice-change-overall',
  templateUrl: './si-voice-change-overall.component.html',
  styleUrls: ['./si-voice-change-overall.component.scss']
})
export class SiVoiceChangeOverallComponent implements OnInit {

  changeId : string = null;
  objectives: ChangeObjectiveModel[] = [];
  objectivesToRemove: string[] = [];
  change: ChangeModel;
  changeWhy : ChangeWhyModel;
  editMode:boolean = false;

  changeForm: FormGroup = this._fb.group({
    changeId:'',
    title:''
  });

  changeWhyForm: FormGroup = this._fb.group({
    changeId:'',
    changeWhyId:'',
    description:'',
    notes:''
  });

  objectiveChanged : boolean = false;
  whyChanged: boolean = false;
  changeChanged : boolean = false;

  changeObjectiveForm: FormArray = this._fb.array([]);


  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _voiceApi : SiVoiceApiService,
              private _fb: FormBuilder) { }

  ngOnInit() {

    this._route.params.subscribe((params: {[key: string]: string}) => {
      if('changeId' in params){
        this.changeId = params.changeId;
        if(this.changeId) {
          this.getChange();
          this.getObjectives();
          this.getChangeWhy();
        }
      }
      else{
        this.editMode = true;
      }
    });
  }

  addObjectiveForm(objectiveModel: ChangeObjectiveModel=null){
    if(!objectiveModel) {
      objectiveModel = {
        changeId: this.changeId,
        changeWhyId: '',
        changeObjectiveId: '',
        priority: 5,
        priorityType: 'Number',
        description: '',
        resolutionDescription: '',
        risks:''
      };
      this.objectives.push(objectiveModel);
    }
    this.changeObjectiveForm.push(this._fb.group(objectiveModel));
  }

  getObjectives(){
    this._voiceApi.getAll(this._voiceApi.baseMethods.changeObjective,
      {changeId: this.changeId, orderBy:'priority'})
      .pipe(take(1))
      .subscribe((objectives : ChangeObjectiveModel[]) => {
        console.log(objectives);
        this.objectives = objectives;
        if(this.objectives){
          for(let obj of this.objectives){
            this.addObjectiveForm(obj);
          }
          console.log(this.changeObjectiveForm.value);
          this.changeObjectiveForm.setValue(this.objectives)

        }
        this.changeObjectiveForm.valueChanges
          .pipe(filter((data) => !this.objectiveChanged))
          .subscribe(data =>
            this.objectiveChanged = true);
      });
  }

  getChange(){
    this._voiceApi.getById(this._voiceApi.baseMethods.change,
      this.changeId)
      .subscribe((change : ChangeModel)=> {
        this.change = change;
        this.changeForm.setValue(this.change);
        this.changeForm.valueChanges
          .pipe(filter(data => !this.changeChanged))
          .subscribe(data =>
            this.changeChanged = true)
      });
  }

  getChangeWhy(){
    this._voiceApi.getAll(this._voiceApi.baseMethods.changeWhy,
      {changeId:this.changeId})
      .subscribe((changeWhy: ChangeWhyModel[]) => {
        console.log('changeWhy', changeWhy);
        if(changeWhy && changeWhy.length) {
          this.changeWhy = changeWhy[0];
          this.changeWhyForm.setValue(this.changeWhy);
        }
        else{
          this.changeWhyForm.controls['changeId'].setValue(this.changeId)
        }
        this.changeWhyForm.valueChanges
          .pipe(filter(data => !this.whyChanged))
          .subscribe(data => {
            console.log('why form changed');
            this.whyChanged = true;
          })
      });
  }

  saveAll(){
    if(this.whyChanged){
      this.saveWhy()
    }
    if(this.changeChanged){
      this.saveChange()
    }
    if(this.objectiveChanged){
      this.saveObjectives();
    }
    this.editMode = false;
  }

  saveChange(){
    if(this.changeForm.value.changeId){
      this._voiceApi.updateRecord(this._voiceApi.baseMethods.change, this.changeForm.value,
        this.changeId)
        .subscribe((data : ChangeModel) => {
            this.changeForm.setValue(data);
            this.change = data;
            this.changeChanged = false;
          }
        )
    }
    else {
      const recordToSave = {
        'title': this.changeForm.value.title
      };
      this._voiceApi.createRecord(this._voiceApi.baseMethods.change, recordToSave)
        .subscribe((data: ChangeModel) => {
          this._router.navigate(['voice','editChange',data.changeId]);

        })
    }
  }

  saveObjectives(){
    console.log('to remove', this.objectivesToRemove);
    for(let objToRemove of this.objectivesToRemove){
      this._voiceApi.deleteRecord(this._voiceApi.baseMethods.changeObjective, objToRemove)
        .subscribe((data) => {
          console.log('Removed objective - ', objToRemove);
        });
    }
    const savedObjectives = [];
    for(let objForm of this.changeObjectiveForm.controls){
      const obj = objForm.value;
      obj.changeId = this.changeId;
      obj.changeWhyId = this.changeWhyForm.value.changeWhyId;
      if(obj.changeObjectiveId){
        this._voiceApi.updateRecord(this._voiceApi.baseMethods.changeObjective,
          <any>obj, obj.changeObjectiveId)
          .subscribe((data) => {
            savedObjectives.push(data);
          })
      }
      else{
        delete obj.changeObjectiveId;
        this._voiceApi.createRecord(this._voiceApi.baseMethods.changeObjective,
          <any>obj)
          .subscribe((data) => {
            savedObjectives.push(data);
          });
      }
    }

    console.log(savedObjectives);
    this.changeObjectiveForm.patchValue(savedObjectives);
    this.objectives = savedObjectives;
    this.objectiveChanged = false;
  }

  saveWhy(){
    if(this.changeWhyForm.value.changeWhyId){
      this._voiceApi.updateRecord(this._voiceApi.baseMethods.changeWhy,
        this.changeWhyForm.value,
        this.changeWhyForm.value.changeWhyId)
        .subscribe((data : ChangeWhyModel) => {
          this.changeWhyForm.setValue(data);
          this.changeWhy = data;
          this.whyChanged = false;
        });
    }
    else {
      const recordToSave = {
        'description': this.changeWhyForm.value.description,
        'notes':this.changeWhyForm.value.notes,
        'changeId': this.changeId
      };
      this._voiceApi.createRecord(this._voiceApi.baseMethods.changeWhy, recordToSave)
        .subscribe((data: ChangeWhyModel) => {
          this.changeWhyForm.setValue(data);
          this.changeWhy = data;
          this.whyChanged = false;
        })
    }
  }

  removeObjective(index: number){
    const objToRemove = this.changeObjectiveForm.at(index).value;
    console.log(objToRemove);
    if(objToRemove.changeObjectiveId){
      this.objectivesToRemove.push(objToRemove.changeObjectiveId);
    }
    this.objectives.splice(index,1);
    this.changeObjectiveForm.removeAt(index);
  }


  resetForms(){
    this.changeForm.setValue(this.change);
    this.changeWhyForm.setValue(this.changeWhy);
    this.changeObjectiveForm.setValue(this.objectives);
    this.editMode = false;
  }

}
