import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input} from '@angular/core';
import {take} from 'rxjs/operators';
import {ChangeQuestionnaireModel} from '../../../../services/voice-types';
import {SiApiService} from '../../../../services/si-api.service';

// import 'src/js/si-forms-builder.min'

@Component({
  selector: 'si-voice-change-requirements',
  templateUrl: './si-voice-change-requirements.component.html',
  styleUrls: ['./si-voice-change-requirements.component.scss']
})
export class SiVoiceChangeRequirementsComponent implements OnInit, AfterViewInit{
  @Input() changeId: string
  @ViewChild('formBuilder', {static:false}) formBuilder: ElementRef

  public questionnaires: ChangeQuestionnaireModel[];
  msg:string = 'ok';
  changeQuestionnaireId: string = null;
  editing: boolean = false;
  constructor(private _siApiService : SiApiService) {
  }

  ngOnInit() {
    this._siApiService.makeGetCall(`/voice/changeQuestionnaires?changeId=${this.changeId}&orderBy=-createDate`)
      .pipe(take(1))
      .subscribe((questionnaires: ChangeQuestionnaireModel[]) =>{
        this.questionnaires = questionnaires;
      });
  }

  ngAfterViewInit(): void {
    // this.formBuilder.nativeElement.setAttribute('change-questionnaire-id', null)
    this.formBuilder.nativeElement.setAttribute('change-id', this.changeId)
  }

  createNewQuestionnaire(): void {
    this.setQuestionnaireId('');
  }
  setQuestionnaireId(changeQuestionnaireId: string){
    this.editing = true;
    this.changeQuestionnaireId = changeQuestionnaireId;
    this.formBuilder.nativeElement.setAttribute('change-questionnaire-id', this.changeQuestionnaireId)
  }
  removeQuestionnaireId(){
    this.editing = false;
    this.changeQuestionnaireId = null;
  }
}
