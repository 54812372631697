import {Component, OnDestroy, OnInit} from '@angular/core';
import {INavItem} from './types/si-nav-types';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from '../common/authentication/auth.service';
import {SiResizeService, WindowWidthBand} from '../services/si-resize.service';

@Component({
  selector: 'si-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  WindowWidthBand = WindowWidthBand;
  currentBand : WindowWidthBand;

  isLoggedIn: boolean = false;
  public navItems: INavItem[] = [
    {
      title: 'connect',
      icon:'project-diagram',
      executeFn:() => {console.log('connect')},
      subRoutes:[
        {title: 'dashboard', route: ['']},
        {title: 'employee profile', route: ['employeeProfile']},
      ]
    },
    {title: 'voice', route:['voice'], icon:"handshake"},
    {
      title: 'admin',
      route: ['companyAdmin'],
      icon:'tasks',
      subRoutes: [
        {title: 'manage users', route: ['companyAdmin', 'users']},
        {title: 'manage groups', route: ['companyAdmin', 'groups']}]
    }

  ];


  public signInOutItem : INavItem[] = [
    { title: 'logout',
      icon:'user',
      executeFn:() => {
        console.log('toggle login');
        this.toggleLogin();
      }
    }
  ];

  public smallNavItems : INavItem[] = [
    {
      title: '', icon:'bars', route:[''],
      subRoutes:[...this.navItems, ...this.signInOutItem]
    }
  ];



  constructor(private router: Router,
              private authService: AuthService,
              private _siResizeService: SiResizeService) {
  }

  ngOnInit() {
    this._siResizeService.getWindowBandSubscription()
      .subscribe((band: WindowWidthBand) => {
        this.currentBand = band;
      });
    this.router.events
      .pipe(filter(value => value instanceof NavigationEnd))
      .subscribe(event => {
        this.isLoggedIn = this.authService.isLoggedIn();
        if(this.isLoggedIn && this.authService.hasRole('SystemAdmin') && !this.navItems.filter( val => val.title === 'sysadmin').length){
          this.navItems.push({
            title: 'sysadmin', route: ['admin'],
            subRoutes: [
              {title: 'associate company', route: ['admin', 'companyAssociation']},
              {title: 'create company admin', route: ['register']},
              {title: 'create company', route: ['admin', 'createCompany']}]
          })
        }
      });

  }



  toggleLogin() {
    if (!this.isLoggedIn) {
      this.router.navigate(['login']);
    } else {
      this.authService.logout();
    }
  }


}
