import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import {AuthService, ICredentials} from "../../common/authentication/auth.service";
import {take} from "rxjs/operators";
import {Router} from "@angular/router";
import {SiUtility} from '../../common/si-utility';

@Component({
  selector: 'si-login',
  templateUrl: './si-login.component.html',
  styleUrls: ['./si-login.component.scss']
})
export class SiLoginComponent implements OnInit {

  public loginGroup = this.fb.group({
    username:['', Validators.required],
    password:['', Validators.required]
  });


  public errorMessage : string = '';

  constructor(private fb : FormBuilder,
              private authService : AuthService,
              private router : Router,
              public utils : SiUtility) {
  }

  ngOnInit(){
  }

  public login(){
    if(!this.loginGroup.valid)
    {
      this.utils.markFormGroupControlsAsTouched(this.loginGroup);
      return;
    }
    const creds = <ICredentials>this.loginGroup.value;
    if(!creds.username || !creds.password){
      this.errorMessage = 'Must provide a username and password';
    }
    this.authService.login(creds)
      .pipe(take(1))
      .subscribe((authResponse) => {
        if(authResponse.success){
          this.router.navigate(['/dashboard']);
        }
        else{
          this.errorMessage = authResponse.message;
        }
      })
  }

  public showErrors(formControl : FormControl | AbstractControl) : boolean
  {
    return !!formControl.touched && !!formControl.errors;
  }
}
