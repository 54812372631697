import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router : Router)
  {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const expectedRole = next.data.expectedRole;
    let canAccess = false;
    if(expectedRole)
    {
       canAccess = this.authService.getRoles().includes(expectedRole);
    }

    if(!canAccess){
      this.router.navigate(['/login']);
    }
    else {
      return true;
    }
    return false;
  }
  
}
