import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class SiApiService {

  constructor(private _http: HttpClient) { }

  public makeGetCall(url : string, options? : any, extraUrl?: string[]) : Observable<any>
  {
    if(url[0] !== '/'){
      url = '/' + url;
    }
    if(extraUrl)
    {
      url += extraUrl.join("/");
    }
    console.log('get req', BASE_URL, url);
    return this._http.get(BASE_URL + url, options);
  }

  public makePostCall(url : string, body? : any, options? : any) : Observable<any>
  {
    if(url[0] !== '/'){
      url = '/' + url;
    }
    console.log('post req', BASE_URL, url);
    return this._http.post(BASE_URL + url, body, options);
  }

  public makePutCall(url : string, body? : any, options? : any) : Observable<any>
  {
    if(url[0] !== '/'){
      url = '/' + url;
    }
    console.log('put req', BASE_URL, url);
    return this._http.put(BASE_URL + url, body, options);
  }

  public makeDeleteCall(url : string ) : Observable<any>
  {
    if(url[0] !== '/'){
      url = '/' + url;
    }
    console.log('delete req', BASE_URL, url);
    return this._http.delete(BASE_URL + url);
  }
}
