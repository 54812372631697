import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {SiApiService} from './si-api.service';

@Injectable({
  providedIn: 'root'
})
export class SiCompanyApiService {

  constructor(private _siApiService : SiApiService) { }


  public getCompanies(): Observable<any> {
    return this._siApiService.makeGetCall("/admin/getCompanies");
  }

  public saveCompany(companyObj: any){
    return this._siApiService.makePostCall("/company/saveCompany", companyObj);
  }

  public listCompanyIntegrations()
  {
    return this._siApiService.makeGetCall("")
  }
}
