import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {SiApiService} from './si-api.service';

@Injectable({
  providedIn: 'root'
})
export class SiAdminApiService {

  constructor(private _siApiService : SiApiService) { }

  public getCompanies(): Observable<any>
  {
    return this._siApiService.makeGetCall("/admin/getCompanies")
  }

  public getUnassociatedIntegrations() : Observable<any>
  {
    return this._siApiService.makeGetCall("/admin/getUnassociatedIntegrations");
  }

  public getCompanyIntegrations(companyId : string)
  {
    return this._siApiService.makeGetCall(`/admin/getCompanyIntegrations/${companyId}`)
  }

  public deassociateIntegrations(integration : any)
  {
    return this._siApiService.makePostCall('/admin/deassociateIntegration', integration)
  }

  public saveCompanyAssociation(companyAssociation: any)
  {
    return this._siApiService.makePostCall('/admin/saveCompanyAssociation', companyAssociation)
  }
}
