import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {SiGraphqlService} from '../../../services/si-graphql.service';
import * as _ from 'lodash';

@Component({
  selector: 'si-dashboard-filters',
  templateUrl: './si-dashboard-filters.component.html',
  styleUrls: ['./si-dashboard-filters.component.scss']
})
export class SiDashboardFiltersComponent implements OnInit {

  @Input() showPersonSelect : boolean = false;
  @Input() showColorSelect : boolean = true;
  @Output() filterVals : EventEmitter<any> = new EventEmitter<any>();

  editFilters : boolean = true;

  public groupBy : {id:string, name:string}[] = [
    {id:'location',name:'Location'}];

  public groupsWithPeople : {id:string, name:string}[] = [{id:'person',name:'Person'}];

  public formGroup : FormGroup = this._fb.group({
    startDate:'',
    endDate:'',
    groupBy:'person',
    colorKey:'location',
    employeeId:''
  });



  constructor(private _fb : FormBuilder,
              private _siGraphQlService : SiGraphqlService) { }

  ngOnInit() {
    const currentDate = moment();
    const sixMonthsPrior = moment().subtract(12, 'months');
    this.formGroup.controls['startDate'].setValue(sixMonthsPrior);
    this.formGroup.controls['endDate'].setValue(currentDate);


    this._siGraphQlService.query('{groups{type}}')
      .subscribe((data:{data:{groups:{type:string}[]}}) => {
        let takenGroups = {};
        if(data && data.data && data.data.groups)
        {
          console.log(data);
          data.data.groups.forEach(g => {
            if(!takenGroups[g.type])
            {
              this.groupBy.push({id:g.type, name:g.type});
            }
            takenGroups[g.type] = true;
          });
          this.groupsWithPeople = [...this.groupsWithPeople,...this.groupBy]
        }
      });
    this.emitFilters();
  }

  changePerson(event: {employeeid: string})
  {
    this.formGroup.controls['employeeId'].setValue(event.employeeid);
  }

  emitFilters()
  {
    const formCopy = _.clone(this.formGroup.value);
    formCopy.startDate = formCopy.startDate.format("MM/DD/YYYY");
    formCopy.endDate = formCopy.endDate.format("MM/DD/YYYY");
    this.filterVals.emit(formCopy);
  }

}
