import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {SiNavItemComponent} from './nav-bar/si-nav-item/si-nav-item.component';
import {SiLoginComponent} from './authentication/si-login/si-login.component';
import {SiRegisterUserComponent} from './authentication/si-register-user/si-register-user.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SiTextFieldComponent} from './common/form/si-text-field/si-text-field.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SiDashboardComponent} from './reporting/si-dashboard/si-dashboard.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthService} from './common/authentication/auth.service';
import {AuthGuard} from './common/authentication/auth.guard';
import {AuthInterceptor} from './common/authentication/auth.interceptor';
import {SiUtility} from './common/si-utility';
import {SiCompanyAssociationComponent} from './admin/si-company-association/si-company-association.component';
import {RoleGuard} from './common/authentication/role.guard';
import {SiCreateCompanyComponent} from './admin/si-create-company/si-create-company.component';
import {SiSelectComponent} from './common/form/si-select/si-select.component';
import {SiCreateCompanyAdminComponent} from './admin/si-create-company-admin/si-create-company-admin.component';
import { SiManageUserInformationComponent } from './company-admin/si-manage-user-information/si-manage-user-information.component';
import { SiLinechartComponent } from './reporting/charts/si-linechart/si-linechart.component';

import { SiManageUserGroupsComponent } from './company-admin/si-manage-user-groups/si-manage-user-groups.component';
import { SiNetworkDiagramComponent } from './reporting/network/si-network-diagram/si-network-diagram.component';
import { SiDashboardFiltersComponent } from './reporting/si-dashboard/si-dashboard-filters/si-dashboard-filters.component';
import {MatDatepickerModule}  from '@angular/material/datepicker';
import {MatButtonModule, MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule,} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatTabsModule} from '@angular/material/tabs';
import { SiStackedBarchartComponent } from './reporting/charts/si-stacked-barchart/si-stacked-barchart.component';
import { SiEmployeeProfileComponent } from './reporting/si-employee-profile/si-employee-profile.component';
import { SiUserSelectComponent } from './common/si-user-select/si-user-select.component';
import { SiPieChartComponent } from './reporting/charts/si-pie-chart/si-pie-chart.component';
import {OverlayModule} from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiGroupedBarchartComponent } from './reporting/charts/si-grouped-barchart/si-grouped-barchart.component';
import { SiTopThreeComponent } from './reporting/kpi/si-top-three/si-top-three.component';
import { SiHeadlineMetricComponent } from './reporting/kpi/si-headline-metric/si-headline-metric.component';
import { SiVoiceDashboardComponent } from './voice/si-voice-dashboard/si-voice-dashboard.component';
import { SiVoiceChangeWizardComponent } from './voice/change/si-voice-change-wizard/si-voice-change-wizard.component';
import { SiVoiceChangeOverallComponent } from './voice/change/si-voice-change-wizard/si-voice-change-overall/si-voice-change-overall.component';
import { SiVoiceChangeRequirementsComponent } from './voice/change/si-voice-change-wizard/si-voice-change-requirements/si-voice-change-requirements.component';



const routes: Routes = [
  {path: 'login', component: SiLoginComponent},
  {
    path: 'register', component: SiRegisterUserComponent
    // canActivate: [AuthGuard, RoleGuard],
    // data: {
    //   expectedRole: 'SystemAdmin'
    // }
  },
  {path: 'dashboard', component: SiDashboardComponent, canActivate: [AuthGuard]},
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {path: 'employeeProfile', component: SiEmployeeProfileComponent, canActivate: [AuthGuard]},
  {
    path: 'companyAdmin',
    canActivate:[AuthGuard, RoleGuard],
    data:{
      expectedRole:"CompanyAdmin"
    },
    children:[
      {path:'',
      component:SiManageUserInformationComponent},
      {
        path:"users",
        component:SiManageUserInformationComponent
      },
      {
        path:"groups",
        component: SiManageUserGroupsComponent
      }
    ]
  },
  {
    path:"voice",
    canActivate:[AuthGuard],
    children:[
      {
        path:'',
        component:SiVoiceDashboardComponent
      },
      {
        path:'createChange',
        component:SiVoiceChangeWizardComponent
      },
      {
        path:'editChange/:changeId',
        component:SiVoiceChangeWizardComponent
      }
    ]
  },
  {
    path:'admin',
    canActivate:[AuthGuard, RoleGuard],
    data: {
      expectedRole:'SystemAdmin',
    },
    children: [
      {
        path:'companyAssociation',
        component:SiCompanyAssociationComponent
      },
      {
        path:"createCompany",
        component:SiCreateCompanyComponent
      },
      {
        path:"createCompanyAdmin",
        component: SiCreateCompanyAdminComponent
      }
    ],

  }
];

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    SiNavItemComponent,
    SiLoginComponent,
    SiRegisterUserComponent,
    SiTextFieldComponent,
    SiDashboardComponent,
    SiCompanyAssociationComponent,
    SiCreateCompanyComponent,
    SiSelectComponent,
    SiCreateCompanyAdminComponent,
    SiManageUserInformationComponent,
    SiLinechartComponent,
    SiManageUserGroupsComponent,
    SiNetworkDiagramComponent,
    SiDashboardFiltersComponent,
    SiStackedBarchartComponent,
    SiEmployeeProfileComponent,
    SiUserSelectComponent,
    SiPieChartComponent,
    SiGroupedBarchartComponent,
    SiTopThreeComponent,
    SiHeadlineMetricComponent,
    SiVoiceDashboardComponent,
    SiVoiceChangeWizardComponent,
    SiVoiceChangeOverallComponent,
    SiVoiceChangeRequirementsComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatTabsModule,
    OverlayModule,
    RouterModule.forRoot(
      routes, {enableTracing: true}
    ),
    BrowserAnimationsModule,
    MatButtonModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    RoleGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    SiUtility
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
