import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';

import {Chart} from 'chart.js';
import * as d3 from 'd3';

@Component({
  selector: 'si-stacked-barchart',
  templateUrl: './si-stacked-barchart.component.html',
  styleUrls: ['./si-stacked-barchart.component.scss']
})
export class SiStackedBarchartComponent implements OnInit, OnChanges {
  @Input() chartId : string ='secondary-test-id';
  @Input() chartTitle : string;
  @Input() chartData : {
    category:string,
    x:any,
    y:number}[];

  stackedBarChart : any;

  @ViewChild('barCanvas', { static: true }) barCanvas : ElementRef;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    this._drawChart();
  }

  private _drawChart() {
    if(this.chartData) {
      const canvas =  this.barCanvas.nativeElement;
      if(canvas)
      {
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0,0,canvas.width, canvas.height);
        const datasets = [];
        const series = {};
        const barLabelObj = {};
        const categoryObj = {}
        this.chartData.forEach((chartItem) => {
          barLabelObj[chartItem.x] = true;
          categoryObj[chartItem.category] = true;
          if(!series[chartItem.category]){
            series[chartItem.category] = {};
          }
          series[chartItem.category][chartItem.x] = {
            y: +chartItem.y
          }
        });

        const colorScheme = d3.scaleOrdinal()
          .domain(Object.keys(categoryObj).sort())
          .range(d3.schemePaired);

        const barLabels = Object.keys(barLabelObj).sort();

        for(let category in series)
        {
          let data = [];
          //keeps order for sub-sets
          barLabels.forEach(label => {
            data.push(series[category][label]);
          });
          datasets.push({
            label:category,
            data:data,
            backgroundColor:colorScheme(category)
          })
        }


        if(!this.stackedBarChart){
          this.stackedBarChart = new Chart(ctx, {
            type:'bar',
            data:{
              labels:barLabels,
              datasets:datasets,
            },
            options:{
              scales:{
                xAxes: [
                  {stacked:true}
                ],
                yAxes: [{
                  stacked:true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',
                  }
                }]
              }
            }
          });
        }
        else {
          this.stackedBarChart.data ={
            labels:barLabels,
            datasets:datasets,
          };
          this.stackedBarChart.update();
        }
      }
    }
  }
}
