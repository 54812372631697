import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'si-top-three',
  templateUrl: './si-top-three.component.html',
  styleUrls: ['./si-top-three.component.scss']
})
export class SiTopThreeComponent implements OnInit {

  @Input() items:string[] | number[] = ['Top','Second','Third'];
  @Input() kpiTitle: string = "Top 3 Title";

  constructor() { }

  ngOnInit() {
  }

}
