import { Injectable } from '@angular/core';
import {SiApiService} from './si-api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiCompanyAdminApiService {

  constructor(private _siApiService : SiApiService) { }

  public downloadUserGroups() : Observable<any>
  {
    return this._siApiService.makeGetCall("/companyAdmin/downloadUserGroups", {responseType:'blob', observe:'response'})
  }

  public uploadUserGroups(groupData:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/companyAdmin/uploadUserGroups", groupData)
  }

  public listCompanyIntegrations() : Observable<any>
  {
    return this._siApiService.makeGetCall("/companyAdmin/listCompanyIntegrations")
  }

  public uploadUsers(userData:any) : Observable<any>
  {
    return this._siApiService.makePostCall("/companyAdmin/uploadUsers", userData)
  }

  public getUsers(companyIntegrationId : string): Observable<any>
  {
    return this._siApiService.makeGetCall(`/companyAdmin/getUsers/${companyIntegrationId}`, {responseType:'blob', observe:'response'})
  }

  public getCompanyEmployees() : Observable<any>
  {
    return this._siApiService.makeGetCall("/companyAdmin/getCompanyEmployees");
  }
}
