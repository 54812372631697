import {Component, Input, OnInit} from '@angular/core';
import {TextFieldConfig} from '../text-field-control-types';

@Component({
  selector: 'si-text-field',
  templateUrl: './si-text-field.component.html',
  styleUrls: ['./si-text-field.component.scss']
})
export class SiTextFieldComponent implements OnInit {

  @Input() config : TextFieldConfig;
  constructor() { }

  ngOnInit() {
  }

}
